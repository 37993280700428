import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';

import { ApiGateway } from '../../../../api/ApiGateway.service';
import { ReportExportType } from '../../../../enums';
import { UnsafeAction as Action } from '../../../../reducers/interfaces';
import { ReportType } from '../../../../reducers/orm/reports/report.model';

export enum ColumnDataType {
  STRING = 'String',
  DECIMAL = 'Decimal',
  PERCENTAGE = 'Percentage',
  MONEY = 'Money',
  DATE = 'Date',
  DATETIME = 'DateTime',
  INT = 'Int',
  TEXT = 'Text',
  TIME = 'Time',
  BOOLEAN = 'Boolean',
}

export interface ReportColumn {
  label: string;
  column: string;
  type: ColumnDataType;
  format: number;
  cellClass: string | Function;
  checked?: boolean;
}

export interface ReportRow {
  [column: string]: any;
}

export interface ReportResponse {
  columns: ReportColumn[];
  rows: ReportRow[];
}

@Injectable()
export class ReportApi {
  public constructor(private apiGateway: ApiGateway) {}

  public periodOverview(filters) {
    return this.getReport('reports/period_overview', filters);
  }

  public schedule(filters) {
    return this.getReport('reports/schedule', filters);
  }

  public scheduleDetail(filters) {
    return this.getReport('reports/schedule_detail', filters);
  }

  public timesheet(filters) {
    return this.getReport('reports/timesheet', filters);
  }

  public timesheetDetail(filters) {
    return this.getReport('reports/timesheet_detail', filters);
  }

  public timesheetDiary(filters) {
    return this.getReport('reports/day_log', filters);
  }

  public timesheetClosed(filters) {
    return this.getReport('reports/finished_timesheet', filters);
  }

  public scheduleTimesheet(filters) {
    return this.getReport('reports/schedule_timesheet', filters);
  }

  public turnover(filters) {
    return this.getReport('reports/turnover', filters);
  }

  public employeesActive(filters) {
    return this.getReport('reports/users', filters);
  }

  public employeesAbsent(filters) {
    return this.getReport('reports/absentee', filters);
  }

  public employeeOvertime(filters) {
    return this.getReport('reports/plus_min', filters);
  }

  public employeeAvailability(filters) {
    return this.getReport('reports/availability', filters);
  }

  public payroll(filters) {
    return this.getReport('reports/payroll', filters);
  }

  public integrationReport(filters) {
    return this.getReport('reports/payroll_integration', filters);
  }

  public timesheetIntegrationReport(filters) {
    return this.getReport('reports/timesheet_integration', filters);
  }

  public requestReport(reportFilters, dispatchStart?: Action) {
    return this.apiGateway.post('reports/request', reportFilters, null, dispatchStart);
  }

  public describe(reportType: ReportType, reportFilters) {
    return this.apiGateway.post('reports/describe', reportFilters);
  }

  public getReports(dispatchStart?: Action) {
    return this.apiGateway.get('reports/', null, dispatchStart);
  }

  public getStatus(reportId: string) {
    return this.apiGateway.get('reports/status/' + reportId);
  }

  public fetchReport(reportId: string, reportExportType: ReportExportType) {
    if (reportExportType !== ReportExportType.HTML) {
      return this.download('reports/' + reportId + '/fetch?format=' + reportExportType);
    }
    return this.apiGateway.post('reports/' + reportId + '/fetch?format=' + reportExportType, null);
  }

  private getReport(endpoint: string, filterValues) {
    if (filterValues['export'] && filterValues['export'] !== 'html') {
      return this.download(endpoint);
    }

    return this.apiGateway.post(endpoint, filterValues);
  }

  private download(endpoint: string) {
    //build request
    const options = {
      responseType: 'blob',
      observe: 'response',
    };

    return this.apiGateway.post(endpoint, null, options).pipe(
      map((res) => {
        const fileName = res.headers.get('X-Report-Filename');

        const blob = new Blob([res.body], {
          type: res.headers.get('Content-Type'),
        });

        saveAs(blob, fileName);
        return 'download';
      })
    );
  }
}

export const convertToDataTableColumns = (columns: ReportColumn[]) =>
  columns.map((column: ReportColumn) => {
    const calculatedWidth = column.label.length * 10;
    const minimumColumnWidth = 200;
    const dataTableColumn = {
      name: column.label,
      prop: column.column,
      type: column.type,
      format: column.format,
      width: calculatedWidth < minimumColumnWidth ? minimumColumnWidth : calculatedWidth,
      cellClass: 'datatable-column-default',
    };

    switch (column.type) {
      case ColumnDataType.DECIMAL:
      case ColumnDataType.INT:
      case ColumnDataType.MONEY:
      case ColumnDataType.PERCENTAGE:
        dataTableColumn.cellClass = 'datatable-column-right-align';
        break;
      case ColumnDataType.STRING:
      case ColumnDataType.TEXT:
        dataTableColumn.cellClass = 'datatable-column-left-align';
        break;
    }

    return dataTableColumn;
  });
