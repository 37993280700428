import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

import {
  ReportFilterName,
  ReportFilters,
} from '../../../+authenticated/+reports/shared/report-filters/report-filter.interfaces';
import { PlanType } from '../../../+authenticated/+reports/shared/subscriptions/subscription.model';
import { ColumnFiltersType } from '../../../enums';
import { IntegrationAppTitle } from '../integration-apps/integration-app.model';

export interface ReportModel {
  id: string;
  display_name: string;
  type: ReportType;
  parameters: any;
  requested: string;
  expires: string;
  status: ReportStatus;
  isRecurring: boolean;
  name: string;
  shouldPoll?: boolean;
  isDownloadingExcel?: boolean;
  isDownloadingCsv?: boolean;
}

export enum ReportStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  FINISHED = 'FINISHED',
}

export enum ReportType {
  DASHBOARD = 'Dashboard',
  PERIOD_OVERVIEW = 'PeriodOverview',
  SCHEDULE_SUMMARY = 'ScheduleSummary',
  SCHEDULE_DETAIL = 'Schedules',
  TIMESHEET_SUMMARY = 'TimesheetSummary',
  TIMESHEET_DETAIL = 'Timesheets',
  TIMESHEET_FINISHED = 'FinishedTimesheets',
  DIARY = 'DayLog',
  SCHEDULE_VS_TIMESHEET = 'ScheduleVsTimesheet',
  EMPLOYEES = 'Employees',
  EMPLOYEES_ABSENCE = 'Absence',
  EMPLOYEES_AVAILABILITY = 'Availabilities',
  EMPLOYEES_PLUS_MINUS = 'PlusMin',
  EMPLOYEES_DAILY_PERIOD_OVERVIEW = 'DailyPeriodOverview',
  TIME_OFF_SUMMARY = 'EmployeeBalanceSummary',
  TIME_OFF_DETAILED = 'EmployeeBalanceDetails',
  TURNOVER = 'Turnover',
  PAYROLL = 'Payroll',
  OPEN_SHIFTS = 'OpenShifts',
  EMPLOYEES_SKILLS = 'Skills',
  PERMISSION_GROUPS = 'PermissionGroups',
  REQUIRED_SHIFTS = 'RequiredShifts',
}

export enum ReportGroupType {
  TOP,
  SCHEDULE,
  TIMESHEET,
  DEFAULT,
  EMPLOYEE,
  TIME_OFF,
  ABSENCE,
  TURNOVER,
}

export const reportGroupName = {
  [ReportGroupType.TIME_OFF]: 'Time off',
  [ReportGroupType.EMPLOYEE]: 'Employee',
  [ReportGroupType.SCHEDULE]: 'Schedule',
  [ReportGroupType.TIMESHEET]: 'Timesheet',
  [ReportGroupType.ABSENCE]: 'Absence',
};

export type ReportOptionModel = {
  [name in ReportType]: {
    options: string[];
    permissions: string[];
    label: string;
    plan: PlanType;
    group: ReportGroupType;
    extendedPermissions?: string[];
    columnType?: ColumnFiltersType;
    url: string;
  };
};

export const reportOptions: ReportOptionModel = {
  [ReportType.DASHBOARD]: {
    options: ['period', 'department', 'groupByLocation', 'groupByPeriod'],
    permissions: [],
    label: _('Report dashboard'),
    plan: PlanType.FREE,
    group: ReportGroupType.TOP,
    url: 'dashboard',
  },
  [ReportType.PERIOD_OVERVIEW]: {
    options: ['period', 'team', 'user', 'contractDepartment', 'contractType', 'groupDayStats'],
    permissions: [],
    label: _('Period overview'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.PERIOD_OVERVIEW,
    group: ReportGroupType.TOP,
    url: 'period-overview',
  },
  [ReportType.SCHEDULE_SUMMARY]: {
    options: ['period', 'team', 'user', 'shift', 'contractDepartment', 'contractType'],
    permissions: ['View reports', 'View all rosters'],
    label: _('Schedule totals'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.SCHEDULE_OVERVIEW,
    group: ReportGroupType.SCHEDULE,
    url: 'schedule-summary',
  },
  [ReportType.SCHEDULE_DETAIL]: {
    options: ['period', 'team', 'user', 'shift', 'contractDepartment', 'contractType'],
    permissions: ['View reports', 'View all rosters'],
    label: _('Schedule detail'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.SCHEDULE_DETAIL,
    group: ReportGroupType.SCHEDULE,
    url: 'schedules',
  },
  [ReportType.OPEN_SHIFTS]: {
    options: ['period', 'team', 'user', 'shift'],
    permissions: ['View reports', 'View all rosters'],
    label: _('Open shifts'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.OPEN_SHIFTS,
    group: ReportGroupType.SCHEDULE,
    url: 'open-shifts',
  },
  [ReportType.REQUIRED_SHIFTS]: {
    options: ['period', 'department', 'team', 'shift'],
    permissions: ['View reports', 'View required shifts'],
    label: _('Required shifts'),
    plan: PlanType.EARLY_ADOPTER,
    group: ReportGroupType.SCHEDULE,
    url: 'required-shifts',
  },
  [ReportType.TIMESHEET_SUMMARY]: {
    options: ['period', 'team', 'user', 'shift', 'contractDepartment', 'contractType'],
    permissions: ['View reports', 'View all timesheets'],
    label: _('Timesheet totals'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.TIMESHEET_OVERVIEW,
    group: ReportGroupType.TIMESHEET,
    url: 'timesheet-summary',
  },
  [ReportType.TIMESHEET_DETAIL]: {
    options: ['period', 'team', 'user', 'shift', 'contractDepartment', 'contractType', 'approvalStatus'],
    permissions: ['View reports', 'View all timesheets'],
    label: _('Timesheet detail'),
    plan: PlanType.FREE,
    columnType: ColumnFiltersType.TIMESHEET_DETAIL,
    group: ReportGroupType.TIMESHEET,
    url: 'timesheets',
  },
  [ReportType.TIMESHEET_FINISHED]: {
    options: ['period', 'department', 'finishedTimesheet'],
    permissions: ['View reports', 'View all timesheets'],
    label: _('Timesheets closed'),
    plan: PlanType.EARLY_ADOPTER,
    columnType: ColumnFiltersType.TIMESHEET_CLOSED,
    group: ReportGroupType.TIMESHEET,
    url: 'finished-timesheets',
  },
  [ReportType.DIARY]: {
    options: ['period', 'department'],
    permissions: ['View reports', 'View log'],
    label: _('Diary'),
    plan: PlanType.EARLY_ADOPTER,
    columnType: ColumnFiltersType.TIMESHEET_DIARY,
    group: ReportGroupType.DEFAULT,
    url: 'day-log',
  },
  [ReportType.SCHEDULE_VS_TIMESHEET]: {
    options: ['period', 'team', 'user', 'shift', 'contractDepartment', 'contractType'],
    permissions: ['View reports', 'View all timesheets', 'View all rosters'],
    label: _('Schedule vs Timesheet'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.SCHEDULE_TIMESHEET,
    group: ReportGroupType.DEFAULT,
    url: 'schedule-vs-timesheet',
  },
  [ReportType.EMPLOYEES_DAILY_PERIOD_OVERVIEW]: {
    options: ['period', 'team', 'user', 'contractDepartment', 'contractType', 'employeeCurrentStatus'],
    permissions: ['View reports', 'View all users'],
    label: _('Daily period overview'),
    plan: PlanType.PREMIUM,
    columnType: ColumnFiltersType.EMPLOYEES,
    group: ReportGroupType.EMPLOYEE,
    url: 'daily-period-overview',
  },
  [ReportType.EMPLOYEES]: {
    options: ['period', 'team', 'user', 'contractDepartment', 'contractType', 'employeeCurrentStatus'],
    permissions: ['View reports', 'View all users'],
    label: _('Employees'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.EMPLOYEES,
    group: ReportGroupType.EMPLOYEE,
    url: 'employees',
  },
  [ReportType.EMPLOYEES_ABSENCE]: {
    options: ['period', 'team', 'user', 'absenteeOption', 'approvalStatus', 'contractDepartment', 'contractType'],
    permissions: ['View reports', 'View absentee'],
    label: _('Requests'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.EMPLOYEES_ABSENT,
    group: ReportGroupType.ABSENCE,
    url: 'absence',
  },
  [ReportType.EMPLOYEES_AVAILABILITY]: {
    options: ['period', 'user', 'department', 'contractDepartment', 'contractType', 'availabilityStatus'],
    permissions: ['View reports', 'View availability'],
    label: _('Employees availability'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.EMPLOYEES_AVAILABILITY,
    group: ReportGroupType.EMPLOYEE,
    url: 'availability',
  },
  [ReportType.EMPLOYEES_PLUS_MINUS]: {
    options: ['period', 'team', 'user', 'contractDepartment', 'contractType', 'groupDayStats'],
    permissions: ['View reports', 'View all users'],
    label: _('Employees plus minus'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.EMPLOYEES_PLUS_MINUS,
    group: ReportGroupType.EMPLOYEE,
    url: 'plus-minus',
  },
  [ReportType.EMPLOYEES_SKILLS]: {
    options: ['period', 'team', 'skill', 'user', 'contractDepartment', 'contractType'],
    permissions: ['View reports'],
    extendedPermissions: ['Edit users', 'Create roster', 'Edit roster'],
    label: _('Skills'),
    plan: PlanType.EARLY_ADOPTER,
    group: ReportGroupType.EMPLOYEE,
    url: 'skills',
  },
  [ReportType.PERMISSION_GROUPS]: {
    options: ['period', 'department', 'team', 'user', 'contractDepartment', 'contractType'],
    permissions: ['View reports', 'View all users', 'Edit user permissions'],
    label: _('Permission groups'),
    plan: PlanType.BASIC,
    group: ReportGroupType.EMPLOYEE,
    url: 'permission-groups',
  },
  [ReportType.TURNOVER]: {
    options: ['period', 'department', 'groupByLocationWithTeam', 'groupByPeriod'],
    permissions: ['View reports', 'View salary', 'View log'],
    label: _('Turnover'),
    plan: PlanType.EARLY_ADOPTER,
    columnType: ColumnFiltersType.TURNOVER,
    group: ReportGroupType.TURNOVER,
    url: 'turnover',
  },
  [ReportType.PAYROLL]: {
    options: ['contractPeriod', 'workedPeriod', 'contractDepartment', 'contractType'],
    permissions: ['View reports', 'View all timesheets'],
    label: _('Payroll'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.PAYROLL,
    group: ReportGroupType.TURNOVER,
    url: 'payroll',
  },
  [ReportType.TIME_OFF_SUMMARY]: {
    options: ['date', 'user', 'team', 'contractDepartment', 'contractType'],
    permissions: ['View reports', 'View time off balances'],
    label: _('Balance summary'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.NONE,
    group: ReportGroupType.TIME_OFF,
    url: 'balance-summary',
  },
  [ReportType.TIME_OFF_DETAILED]: {
    options: ['period', 'user', 'team', 'timeOffBalance', 'contractDepartment', 'contractType', 'groupByPeriod'],
    permissions: ['View reports', 'View time off balances'],
    label: _('Balance details'),
    plan: PlanType.BASIC,
    columnType: ColumnFiltersType.NONE,
    group: ReportGroupType.TIME_OFF,
    url: 'balance-details',
  },
};

export enum IntegrationColumnType {
  EXACT = 'exact-columns',
  LOKETV2 = 'loketv2-columns',
  DATEV = 'datev-columns',
  CONNEXIE = 'connexie-columns',
  EASYLON = 'easylon-columns',
  EUUR = 'euur-columns',
  LOKET = 'loket-columns',
  NMBRS = 'nmbrs-columns',
  EMPLOYES = 'employes-columns',
  AFAS = 'afas-columns',
  HR_EN_SALARIS_GEMAK = 'hr-en-salaris-gemak-columns',
  LOON_NL = 'loon-nl-columns',
  SD_WORX = 'sd-worx-columns',
  PERSONIO = 'personio-columns',
  BCS = 'bcs-columns',
  HOORAY = 'hooray-columns',
}

export interface IntegrationOptionModel {
  [propName: string]: {
    options: ReportFilterName[];
    columnType: IntegrationColumnType;
    permissions?: string[];
  };
}

export const integrationOptions: IntegrationOptionModel = {
  [IntegrationAppTitle.EXACT]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod', 'payroll_year', 'payroll_period'],
    columnType: IntegrationColumnType.EXACT,
  },
  [IntegrationAppTitle.DATEV]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod', 'payroll_year', 'payroll_period'],
    columnType: IntegrationColumnType.DATEV,
  },
  [IntegrationAppTitle.CONNEXIE]: {
    options: ['workedPeriod'],
    columnType: IntegrationColumnType.CONNEXIE,
  },
  [IntegrationAppTitle.EASYLON]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod'],
    columnType: IntegrationColumnType.EASYLON,
  },
  [IntegrationAppTitle.LOON_NL]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod'],
    columnType: IntegrationColumnType.LOON_NL,
  },
  [IntegrationAppTitle.SD_WORX]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod'],
    columnType: IntegrationColumnType.SD_WORX,
  },
  [IntegrationAppTitle.EUUR]: {
    options: ['period'],
    columnType: IntegrationColumnType.EUUR,
  },
  [IntegrationAppTitle.LOKET_V2]: {
    options: [
      'include_period_type',
      'contractPeriod',
      'workedPeriod',
      'payslip_type',
      'payroll_year',
      'payroll_period',
    ],
    columnType: IntegrationColumnType.LOKETV2,
  },
  [IntegrationAppTitle.NMBRS]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod'],
    columnType: IntegrationColumnType.NMBRS,
  },
  [IntegrationAppTitle.EMPLOYES]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod'],
    columnType: IntegrationColumnType.EMPLOYES,
  },
  [IntegrationAppTitle.AFAS]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod', 'payroll_year', 'payroll_period'],
    columnType: IntegrationColumnType.AFAS,
  },
  [IntegrationAppTitle.HR_EN_SALARIS_GEMAK]: {
    options: [
      'include_period_type',
      'contractPeriod',
      'workedPeriod',
      'payroll_year',
      'payroll_period',
      'payroll_frequency',
    ],
    columnType: IntegrationColumnType.HR_EN_SALARIS_GEMAK,
  },
  [IntegrationAppTitle.PERSONIO]: {
    options: ['period'],
    columnType: IntegrationColumnType.PERSONIO,
  },
  [IntegrationAppTitle.PILOGA]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod'],
    columnType: IntegrationColumnType.PERSONIO,
  },
  [IntegrationAppTitle.BCS]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod'],
    columnType: IntegrationColumnType.BCS,
  },
  [IntegrationAppTitle.HOORAY]: {
    options: ['include_period_type', 'contractPeriod', 'workedPeriod'],
    columnType: IntegrationColumnType.HOORAY,
  },
};

export interface ReportRequestModel {
  type: ReportType;
  options?: ReportFilters | any;
  columns?: any[];
}

export interface ReportIntegrationRequestModel {
  id: number;
  options: any;
  display_name: string;
  type: 'TimesheetExport' | 'PayrollExport';
  columnType: IntegrationColumnType;
  integrationConfig: any;
}

export interface SelectedReportModel {
  title: IntegrationAppTitle;
  id: number;
  display_name: string;
}

export interface ReportGroupModel {
  group: ReportGroupType;
  groupName: string;
  reports: ReportType[];
  hasSubmenu: boolean;
}
