import { UnsafeAction, UnsafeAction as Action } from '../../interfaces';
import { ReportModel } from './report.model';
import { Update } from '@ngrx/entity';

const ACTION_PREFIX = '[Report]';

export const reportActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,
  UPSERT: `${ACTION_PREFIX} Upsert`,
  UPSERT_SUCCESS: `${ACTION_PREFIX} Upsert Success`,
  UPSERT_FAILED: `${ACTION_PREFIX} Upsert Failed`,
  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,
  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,
};

export class ReportAction {
  static load(): Action {
    return {
      type: reportActionType.LOAD,
    };
  }

  static loadSuccess(reports: ReportModel[]): UnsafeAction {
    return {
      type: reportActionType.LOAD_SUCCESS,
      payload: reports,
    };
  }

  static loadFailed(err): Action {
    return {
      type: reportActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static upsert(): Action {
    return {
      type: reportActionType.UPSERT,
    };
  }

  static upsertSuccess(reports: ReportModel[]): UnsafeAction {
    return {
      type: reportActionType.UPSERT_SUCCESS,
      payload: reports,
    };
  }

  static upsertFailed(err): Action {
    return {
      type: reportActionType.UPSERT_FAILED,
      payload: err,
    };
  }

  static add(): Action {
    return {
      type: reportActionType.ADD,
    };
  }

  static addSuccess(payload): UnsafeAction {
    return {
      type: reportActionType.ADD_SUCCESS,
      payload,
    };
  }

  static addFailed(err): Action {
    return {
      type: reportActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(payload: Update<ReportModel>): Action {
    return {
      type: reportActionType.UPDATE,
      payload,
    };
  }

  static updateSuccess(payload: Update<ReportModel>): UnsafeAction {
    return {
      type: reportActionType.UPDATE_SUCCESS,
      payload,
    };
  }

  static updateFailed(err): Action {
    return {
      type: reportActionType.UPDATE_FAILED,
      payload: err,
    };
  }
}
