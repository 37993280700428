import { Injectable } from '@angular/core';
import { AppState } from '../../index';
import { Store } from '@ngrx/store';
import { ReportRecurringApi } from './report-recurring.api';
import {
  ReportProjectionsRequestModel,
  ReportProjectionsResponseModel,
  ReportRecurringModel,
  ReportRecurringRequestModel,
} from './report-recurring.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { reportRecurringDeleteSuccess, reportRecurringLoadSuccess } from './report-recurring.action';

@Injectable()
export class ReportRecurringService {
  public constructor(private api: ReportRecurringApi, private store: Store<AppState>) {}

  public createReport(data: ReportRecurringRequestModel): Observable<ReportRecurringModel> {
    return this.api.create(data);
  }

  public getProjections(data: ReportProjectionsRequestModel): Observable<ReportProjectionsResponseModel[]> {
    return this.api.projections(data);
  }

  public getReports(): Observable<ReportRecurringModel[]> {
    return this.api.getReports().pipe(
      tap((reports) => {
        this.store.dispatch(reportRecurringLoadSuccess({ reports }));
      })
    );
  }

  public delete(id: string) {
    return this.api.deleteReport(id).pipe(
      tap(() => {
        this.store.dispatch(reportRecurringDeleteSuccess({ id }));
      })
    );
  }
}
