import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ReportRecurringModel } from './report-recurring.model';

export interface ReportRecurringState extends EntityState<ReportRecurringModel> {
  isLoading?: boolean;
  error?: any;
}

export const reportRecurringEntityAdapter: EntityAdapter<ReportRecurringModel> =
  createEntityAdapter<ReportRecurringModel>();

export const initialState: ReportRecurringState = reportRecurringEntityAdapter.getInitialState({
  isLoading: false,
  error: null,
});
