import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import omit from 'lodash-es/omit';
import { BehaviorSubject, Subject } from 'rxjs';
import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { catchError, map } from 'rxjs/operators';

import { ReportApi } from '../../../+authenticated/+reports/shared/api/ReportApi';
import { ReportExportType } from '../../../enums';
import { AppState } from '../../index';
import { IntegrationAppTitle } from '../integration-apps/integration-app.model';
import { ReportFavoriteModel } from '../report-favorites/report-favorite.model';
import { ReportAction } from './report.action';
import { reportOptions, ReportRequestModel, ReportType } from './report.model';

export interface SelectedReport {
  type: 'report' | 'integration';
  report?: ReportType;
  app?: { integrationId: string; appTitle: IntegrationAppTitle; displayName: string };
  parameters?: any;
}

@Injectable()
export class ReportService {
  public currentSelectedReport = new BehaviorSubject<SelectedReport>({ type: 'report', report: ReportType.DASHBOARD });

  public reportParametersWithType = new BehaviorSubject<Partial<ReportFavoriteModel>>(null);

  public updateFilters = new BehaviorSubject<any>(null);
  public fetchReports = new BehaviorSubject(true);

  private pollReports = new Subject();

  public constructor(
    private store: Store<AppState>,
    private api: ReportApi,
    private router: Router,
  ) {}

  public selectReport(payload: SelectedReport, navigateToReport = true) {
    this.currentSelectedReport.next(payload);

    if (navigateToReport && payload.type === 'report') {
      const url = `/reports/${reportOptions[payload.report].url}`;
      this.router.navigateByUrl(url);
    }
  }

  public setFilters(report) {
    this.updateFilters.next(this.transformFiltersToWebFormat(report.parameters));
  }

  public getReportParameters() {
    if (!this.reportParametersWithType.value) {
      return null;
    }
    const parameters = this.reportParametersWithType.value.parameters;

    const updatedParams = omit(parameters, ['period', 'date', 'workedPeriod', 'contractPeriod']);
    return {
      ...this.reportParametersWithType.value,
      parameters: updatedParams,
    };
  }

  public pollReportSubject() {
    return this.pollReports;
  }

  public loadReports() {
    return this.api.getReports(ReportAction.load()).pipe(
      map((res: any) => {
        this.store.dispatch(ReportAction.loadSuccess(res));
        return res;
      }),
      catchError((response) => {
        this.store.dispatch(ReportAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public getReports() {
    return this.api.getReports(ReportAction.upsert()).pipe(
      map((res: any) => {
        this.store.dispatch(ReportAction.upsertSuccess(res));
        return res;
      }),
      catchError((response) => {
        this.store.dispatch(ReportAction.upsertFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public pollReport() {
    this.pollReports.next(null);
  }

  public fetchReport(reportId: string, reportExportType: ReportExportType) {
    return this.api.fetchReport(reportId, reportExportType);
  }

  public triggerReport(reportRequest: ReportRequestModel) {
    return this.api
      .requestReport(
        {
          parameters: { ...this.transformFiltersToApiFormat(reportRequest.options), columns: reportRequest.columns },
          type: reportRequest.type,
        },
        ReportAction.add(),
      )
      .pipe(
        map((res: any) => {
          this.store.dispatch(ReportAction.addSuccess(res));
          return res;
        }),
        catchError((response) => {
          this.store.dispatch(ReportAction.addFailed(response));
          return observableThrowError(response);
        }),
      );
  }

  public describe(reportType: ReportType, reportFilters) {
    const describeRequest = {
      ...this.transformFiltersToApiFormat(reportFilters),
      reportType,
    };
    return this.api.describe(reportType, describeRequest);
  }

  private transformFiltersToApiFormat(filterValues) {
    let filters = filterValues;

    if (filters.period) {
      const period = filters.period;

      filters = {
        ...omit(filters, ['period']),
        from: period[0],
        to: period[1],
      };
    }

    if (filters.contractPeriod) {
      const periodContract = filters.contractPeriod;

      filters = {
        ...omit(filters, ['contractPeriod']),
        contractPeriod: {
          from: periodContract[0],
          to: periodContract[1],
        },
      };
    }

    if (filters.workedPeriod) {
      const periodWorked = filters.workedPeriod;

      filters = {
        ...omit(filters, ['workedPeriod']),
        workedPeriod: {
          from: periodWorked[0],
          to: periodWorked[1],
        },
      };
    }

    return filters;
  }

  private transformFiltersToWebFormat(parameters) {
    const { period, contractPeriod, workedPeriod, ...rest } = parameters;
    const normalizedParameters = {
      ...rest,
    };

    if (period) {
      normalizedParameters.period = [period.from, period.to];
    }
    if (contractPeriod) {
      normalizedParameters.contractPeriod = [contractPeriod.from, contractPeriod.to];
    }
    if (workedPeriod) {
      normalizedParameters.workedPeriod = [workedPeriod.from, workedPeriod.to];
    }

    return {
      ...normalizedParameters,
    };
  }
}
