import { createAction, props } from '@ngrx/store';
import { ReportRecurringModel } from './report-recurring.model';

export enum ReportRecurringActionType {
  LOAD_SUCCESS = '[Report Recurring] Load Success',
  DELETE_SUCCESS = '[Report Recurring] Delete Success',
}

export const reportRecurringLoadSuccess = createAction(
  ReportRecurringActionType.LOAD_SUCCESS,
  props<{ reports: ReportRecurringModel[] }>()
);

export const reportRecurringDeleteSuccess = createAction(
  ReportRecurringActionType.DELETE_SUCCESS,
  props<{ id: string }>()
);
