import { Injectable } from '@angular/core';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { ReportRecurringRequestModel } from './report-recurring.model';
import { UnsafeAction as Action } from '../../interfaces';

@Injectable()
export class ReportRecurringApi {
  private endpoint = 'reporting/recurring';

  public constructor(private gateway: ApiGateway) {}

  public create(data: ReportRecurringRequestModel, dispatchStart?: Action) {
    return this.gateway.post(this.endpoint, data, undefined, dispatchStart);
  }

  public projections(data: Partial<ReportRecurringRequestModel>, dispatchStart?: Action) {
    return this.gateway.post(this.endpoint + '/projections', data, undefined, dispatchStart);
  }

  public getReports() {
    return this.gateway.get(this.endpoint);
  }

  public deleteReport(id: string) {
    return this.gateway.delete(this.endpoint + '/' + id);
  }
}
